import client from '../request'

//get all
const getAll = '/customers'

//get by id
const get = (id) => {
  return `/customers/${id}`
}

//get by email
const getByEmail = (email) => {
  return `/customers/by-email?email=${email}`
}

//get order of customer
const getOrder = (id) => {
  return `/customers/${id}/order`
}

const getProduct = (id) => {
  return `/customers/${id}/product`
}

// axios
const create = (data) => {
  return client.post('/customers', data)
}

// axios
const update = (id, data) => {
  return client.put(`/customers/${id}`, data)
}

const toRow = (data) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

const toPaginate = (data) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const CustomerService = {
  getAll,
  get,
  getByEmail,
  getOrder,
  getProduct,
  create,
  update,
  toRow,
  toPaginate
}

export default CustomerService
