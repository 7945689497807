import React from 'react'
import { Button, Checkbox, Col, Row, Form, message, Select, DatePicker } from 'antd'
import { saveAs } from 'file-saver'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import ReportService from '../../network/services/report'
import { useState } from 'react'
import Gaps from '../../components/Gaps'
import { DownloadOutlined } from '@ant-design/icons'

const FulfillmentReport = () => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [startsAt, setStartsAt] = useState('')
  const [endsAt, setEndsAt] = useState('')

  const { t } = useTranslation('translation', { keyPrefix: 'dashboard' })
  const { t: generalT } = useTranslation('translation')
  // const [startDate, setStartDate] = useState(moment().add(1, 'day').format('YYYY-MM-DD'))
  // const [endDate, setEndDate] = useState(moment().add(1, 'day').format('YYYY-MM-DD'))

  const handleDownload = async (values) => {
    setLoading(true)
    const data = {
      starts_at: startsAt,
      ends_at: endsAt,
      sort: `${values.sort}:${values.ordering}`
    }
    console.log(data)
    try {
      const { data: response } = await ReportService.fulfillment(data)
      console.log(response)
      saveAs(response, 'fulfillment_report.csv')
    } catch (e) {
      const text = (await e.response?.data?.text()) ?? '{}'
      console.log({ data: JSON.parse(text), ...e })
      message.error(text)
    }
    setLoading(false)
  }

  return (
    <Form
      form={form}
      name="download_csv"
      layout="vertical"
      onFinish={handleDownload}
      initialValues={{
        sort: 'total',
        ordering: 'desc'
      }}
    >
      <Row gutter={[16, 16]}>
        <DatePicker.RangePicker
          showTime
          onChange={(date, _) => {
            setStartsAt(date[0].toISOString())
            setEndsAt(date[1].toISOString())
          }}
        />
      </Row>

      <Gaps />

      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Form.Item name="sort" label={generalT('Sort by')}>
            <Select>
              <Select.Option value="line_items.product_title">Product Name</Select.Option>
              <Select.Option value="line_items.sku">SKU</Select.Option>
              <Select.Option value="total">Quantity</Select.Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="ordering" label={generalT('Direction')}>
            <Select>
              <Select.Option value="asc">{generalT('Ascending')}</Select.Option>
              <Select.Option value="desc">{generalT('Descending')}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Gaps />
      <Row gutter={[12, 12]}>
        <Col span={24} order="center">
          <Button
            type="primary"
            shape="round"
            icon={<DownloadOutlined />}
            size={'large'}
            onClick={() => form.submit()}
            loading={loading}
          >
            {generalT('Download')}
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default FulfillmentReport
