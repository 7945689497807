import invoicePdfClient from '../invoicePdfClient'
import { serialize } from '../request'

// useSWR, return strings
const getRyoushuusho = (data) => {
  return invoicePdfClient.get(serialize('/react-ryoushuusho', data))
}

const getSeikyuusho = (data) => {
  return invoicePdfClient.get(serialize('/react-seikyuusho', data))
}

const getNouhinsho = (data) => {
  return invoicePdfClient.get(serialize('/react-nouhinsho', data))
}

const InvoicePdfService = {
  getRyoushuusho,
  getSeikyuusho,
  getNouhinsho
}

export default InvoicePdfService
