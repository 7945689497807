import React from 'react'
import { Layout, Row, Space, Typography, Menu, Select } from 'antd'
import { useSnapshot } from 'valtio'
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  TranslationOutlined
} from '@ant-design/icons'
import { Link, Outlet } from 'react-router-dom'

import AuthService from '../network/services/auth'

import useSidebarOption from '../lib/sidebar'
import globalStore from '../lib/store/global'
import authStore, { logout } from '../lib/store/auth'
import languageStore from '../lib/store/language'

const { Header, Footer, Sider, Content } = Layout

const AuthenticatedLayout = ({ children }) => {
  const { collapsed } = useSnapshot(globalStore)
  const options = useSidebarOption()
  const {
    state: { acl }
  } = useSnapshot(authStore)
  const {
    state: { language }
  } = useSnapshot(languageStore)

  const toggle = () => {
    globalStore.collapsed = !collapsed
  }

  const setLanguage = (value) => {
    languageStore.state.language = value
  }

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <Space
          className="sider__space"
          direction="vertical"
          style={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}
        >
          <div style={{ minHeight: 60 }} className="flex-center">
            <Link to="/dashboard">
              {collapsed ? (
                <Typography.Title level={4} style={{ margin: 0 }}>
                  C
                </Typography.Title>
              ) : (
                <Typography.Title level={4} style={{ margin: 0 }}>
                  Casefinite
                </Typography.Title>
              )}
            </Link>
          </div>

          <Menu
          // openKeys={isCollapsed ? [] : openKeys}
          // selectedKeys={current}
          // onOpenChange={onOpenChange}
          >
            {options.map((option) => {
              if (acl <= option.level) {
                return (
                  <Menu.Item key={option.key}>
                    <Link to={`${option.key}`}>
                      {option.icon}
                      <span>{option.label}</span>
                    </Link>
                  </Menu.Item>
                )
              }

              return <></>
            })}
          </Menu>
        </Space>
      </Sider>
      <Layout>
        <Header style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
          {collapsed ? (
            <MenuUnfoldOutlined onClick={toggle} />
          ) : (
            <MenuFoldOutlined onClick={toggle} />
          )}

          <Space>
            <TranslationOutlined />
            <Select
              value={language}
              onChange={setLanguage}
              style={{ width: 200, textAlign: 'center' }}
            >
              <Select.Option value="en">English</Select.Option>
              <Select.Option value="ja">日本語</Select.Option>
            </Select>

            <LogoutOutlined
              onClick={async () => {
                const { data: result } = await AuthService.logout()
                if (result.success) {
                  logout()
                }
              }}
            />
          </Space>
        </Header>
        <Content>
          <Outlet />
        </Content>
        <Footer>
          <Row>
            <Typography.Text style={{ margin: 'auto' }}>
              copyright @ {new Date().getFullYear()}
            </Typography.Text>
          </Row>
        </Footer>
      </Layout>
    </Layout>
  )
}

export default AuthenticatedLayout
