import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, Col, Form, Input, Row, message, Select, Rate, Checkbox, Button } from 'antd'
import moment from 'moment'

import ThumbnailUpload from '../../components/ThumbnailUpload'

import ReviewService from '../../network/services/review'

import Gaps from '../../components/Gaps'
import _ from 'lodash'
// import { useMediaQuery } from 'react-responsive'

const ReviewForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh,
  customers,
  handleCustomerSearch,
  products,
  handleProductSearch,
  updateAllowed = false,
  createAllowed = false,
  readAllowed = false,
  deleteAllowed = false
}) => {
  const { id } = useParams()
  const { Option } = Select
  const navigate = useNavigate()
  const [currentImage, setCurrentImage] = useState(null)
  const [imageChange, setImageChange] = useState(false)
  // const isDesktopOrLaptop = useMediaQuery({
  //   query: '(min-width: 992px)'
  // })

  const [isReadOnly, setIsReadOnly] = useState(false)
  useEffect(() => {
    //check the access level
    if ((initialValue != null && !readAllowed) || (initialValue === null && !createAllowed)) {
      navigate('/reviews')
    }
  })

  useEffect(() => {
    setIsReadOnly(!updateAllowed && initialValue != null)
  }, [updateAllowed, initialValue])

  const handlePublish = async (published) => {
    try {
      const { data: result } = await ReviewService.update(initialValue.id, {
        published
      })
      if (result.success) {
        message.success(`Successfully ${published ? 'published' : 'unpublished'} review`)
        refresh()
      }
    } catch (error) {
      message.error(`${published ? 'Publish' : 'Unpublish'} review error ` + error.message)
    }
  }

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    if (initialValue?.id) {
      // update return status
      try {
        const { data: result } = await ReviewService.update(initialValue.id, {
          ...values,
          up_votes: undefined,
          down_votes: undefined,
          up_votes_offset: values.up_votes - (initialValue.up_votes - initialValue.up_votes_offset),
          down_votes_offset:
            values.down_votes - (initialValue.down_votes - initialValue.down_votes_offset),
          product_id: _.toString(values.product_id)
        })
        if (result.success) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update review error ' + error.message)
      }
    } else {
      // new return status
      try {
        const { data: result } = await ReviewService.create({
          ...values,
          up_votes: undefined,
          down_votes: undefined,
          up_votes_offset: values.up_votes,
          down_votes_offset: values.down_votes,
          product_id: _.toString(values.product_id)
        })
        if (result.success) {
          message.success('Create successful')
          navigate(`/dashboard/reviews/${result.data.id}`)
        }
      } catch (error) {
        message.error('Create review error ' + error.message)
      }
    }

    setIsLoading(false)
  }

  return (
    <>
      <Form
        form={form}
        name="new-source"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue,
          order_date: initialValue?.order_date ? moment(initialValue.order_date) : null,
          condition_match: initialValue == null ? 'all' : initialValue.condition_match,
          sort_order: initialValue == null ? 'ascending' : initialValue.sort_order
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
            <Card bordered={false}>
              {/* <Form.Item name="customer_id" label="Customer">
                <Select
                  showSearch
                  placeholder="Select a customer with email or name"
                  onSearch={handleCustomerSearch}
                  disabled={initialValue !== null}
                  filterOption={false}
                >
                  {customers?.length > 0 &&
                    customers.map((customer) => {
                      return (
                        <Option key={customer.id} value={customer.id}>
                          <Space direction="vertical">
                            <div> {customer.name}</div>
                            <div> {customer.email}</div>
                          </Space>
                        </Option>
                      )
                    })}
                </Select>
              </Form.Item> */}

              <Form.Item name="product_id" label="Product">
                <Select
                  showSearch
                  placeholder="Select a product"
                  onSearch={handleProductSearch}
                  // disabled={initialValue !== null}
                  filterOption={false}
                >
                  {products?.length > 0 &&
                    products.map((product) => (
                      <Option key={product.id} value={product.id}>
                        {product.product_name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              {/* <Form.Item name="order_date" label="Order Date">
                <DatePicker />
              </Form.Item> */}
              <Form.Item name="email" label="Email">
                <Input readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item name="name" label="Name">
                <Input readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item name="title" label="Title">
                <Input readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item name="content" label="Content">
                <Input.TextArea autoSize={{ minRows: 4 }} readOnly={isReadOnly} />
              </Form.Item>

              <Form.Item name="score" label="Rating">
                <Rate readOnly={isReadOnly} />
              </Form.Item>

              <Row gutter={[16, 16]}>
                <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }}>
                  <Form.Item
                    name="up_votes"
                    label={
                      initialValue
                        ? `Up Votes (actual votes ${
                            initialValue.up_votes - initialValue.up_votes_offset
                          })`
                        : 'Initial Up Votes'
                    }
                    // rules={[
                    //   {
                    //     min: 0,
                    //     message: 'The value cannot be lower than 0'
                    //   }
                    // ]}
                  >
                    <Input type="number" readOnly={isReadOnly} min={0} />
                  </Form.Item>
                </Col>

                <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }}>
                  <Form.Item
                    name="down_votes"
                    label={
                      initialValue
                        ? `Down Votes (actual votes ${
                            initialValue.down_votes - initialValue.down_votes_offset
                          })`
                        : 'Initial Down Votes'
                    }
                    // rules={[
                    //   {
                    //     min: 0,
                    //     message: 'The value cannot be lower than 0'
                    //   }
                    // ]}
                  >
                    <Input type="number" readOnly={isReadOnly} min={0} />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item label="Verified Buyer" name="verified_buyer" valuePropName="checked">
                <Checkbox />
              </Form.Item>

              {id !== 'new' && (
                <Form.Item label="Manual Create?">
                  <Checkbox checked={!!initialValue.admin_id} disabled />
                </Form.Item>
              )}

              {id !== 'new' && (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    onClick={() => handlePublish(!initialValue.published)}
                    type={initialValue.published ? 'default' : 'primary'}
                    htmlType="button"
                  >
                    {initialValue.published ? 'Unpublish' : 'Publish'}
                  </Button>
                </div>
              )}
            </Card>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }}>
            <ThumbnailUpload
              initialValue={initialValue}
              isLoading={isLoading}
              setCurrentImage={setCurrentImage}
              setImageChange={setImageChange}
              optional={false}
            />
          </Col>
        </Row>
        <Gaps />
      </Form>
    </>
  )
}

export default ReviewForm
