import React, { createContext, useContext, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Card,
  Col,
  Form,
  Row,
  message,
  Table,
  Select,
  Checkbox,
  Button,
  Space,
  Modal,
  Input,
  Typography
} from 'antd'
import invoicePdfService from '../../network/services/invoicePdf'
import OrderService from '../../network/services/order'
import Gaps from '../../components/Gaps'
import { useState } from 'react'
import { Option } from 'antd/lib/mentions'
import { saveAs } from 'file-saver'
import { CheckOutlined, CopyOutlined, LinkOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import useSWR from 'swr'
import EmailTemplateService from '../../network/services/emailTemplate'
import { useForm } from 'antd/lib/form/Form'

const orderFormContext = createContext()

const OrderForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh,
  transactionStatuses,
  orderStatuses,
  updateAllowed = false,
  createAllowed = false,
  readAllowed = false,
  deleteAllowed = false
}) => {
  const navigate = useNavigate()

  const [isReadOnly, setIsReadOnly] = useState(false)
  const [ryoushuushoModalVisible, setRyoushuushoModalVisible] = useState(false)
  const [seikyuushoModalVisible, setSeikyuushoModalVisible] = useState(false)
  const [nouhinshoModalVisible, setNouhinshoModalVisible] = useState(false)
  const [emailModalDisplay, setEmailModalDisplay] = useState(false)
  const [templateId, setTemplateId] = useState(0)
  const { data: emailTemplates, mutate } = useSWR(EmailTemplateService.getAll)

  useEffect(() => {
    //check the access level
    if ((initialValue != null && !readAllowed) || (initialValue === null && !createAllowed)) {
      navigate('/order')
    }
  })

  useEffect(() => {
    setIsReadOnly(!updateAllowed && initialValue != null)
  }, [updateAllowed, initialValue])

  // const datetimeNotSpefied = useMemo(() => {
  //   if (!initialValue.note_obj || initialValue.note_obj.length == 0) return true
  //   const date = initialValue.note_obj.find((item) => item.name === 'date')?.value
  //   const time = initialValue.note_obj.find((item) => item.name === 'time')?.value

  //   return !date || !time || date === '指定なし' || time === '指定なし'
  // }, [initialValue])

  const productCol = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku'
    },
    {
      title: 'Name',
      key: 'name',
      render: (record) => {
        return `${record.product_title} - ${record.variant_title}`
      }
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: 'Fulfilled Quantity',
      key: 'quantity',
      render: (record) => {
        return `${record.quantity - record.fulfillable_quantity} / ${record.quantity}`
      }
    }
  ]

  const fulfillmentCol = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku'
    },
    {
      title: 'Name',
      key: 'name',
      render: (record) => {
        return `${record.product_title} - ${record.variant_title}`
      }
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity'
    }
  ]

  // const handleSendDeliveryMail = async () => {
  //   if (isLoading) return
  //   setIsLoading(true)

  //   if (initialValue?.id) {
  //     // update return status
  //     try {
  //       const { data: result } = await OrderService.sendDeliveryEmail(initialValue.id)
  //       if (result) {
  //         message.success('Email sent')
  //         refresh()
  //       }
  //     } catch (error) {
  //       message.error('Error sending confirmation email ' + error.message)
  //     }
  //   }
  //   setIsLoading(false)
  // }

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    if (initialValue?.id) {
      // update return status
      try {
        const { data: result } = await OrderService.updateMemo(initialValue.id, values)
        if (result) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update order error ' + error.message)
      }
    }
    setIsLoading(false)
  }

  const handleSendEmail = async () => {
    if (isLoading) return
    setIsLoading(true)

    if (initialValue?.id) {
      // update return status
      try {
        const { data: result } = await OrderService.sendEmailFromTemplate(
          initialValue.id,
          templateId
        )
        if (result) {
          message.success('Email sent')
          refresh()
        }
      } catch (error) {
        message.error('Error sending confirmation email ' + error.message)
      }
    }
    setEmailModalDisplay(false)
    setTemplateId(0)
    setIsLoading(false)
  }

  return (
    <orderFormContext.Provider value={{ initialValue }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <Space>
          <Button onClick={() => setRyoushuushoModalVisible(true)}>領収書</Button>
          <Button onClick={() => setSeikyuushoModalVisible(true)}>請求書</Button>
          <Button onClick={() => setNouhinshoModalVisible(true)}>納品書</Button>
        </Space>

        <div>
          <Select
            style={{ width: 400 }}
            value={templateId == 0 ? null : templateId}
            placeholder="メール送信"
            onChange={(value) => {
              console.log(value)
              setEmailModalDisplay(true)
              setTemplateId(value)
            }}
          >
            {emailTemplates?.length > 0 &&
              emailTemplates.map((item) => {
                const sent =
                  initialValue.mail_delivery != null
                    ? initialValue.mail_delivery[item.id.toString()]?.delivered
                    : false
                return (
                  <Select.Option value={item.id} key={item.id}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <Typography.Text>{item.name}</Typography.Text>
                      {sent ? <CheckOutlined color="green" /> : null}
                    </div>
                  </Select.Option>
                )
              })}
          </Select>
        </div>
      </div>
      <Gaps />

      <SendEmailModal
        visible={emailModalDisplay}
        onCancel={() => {
          setEmailModalDisplay(false)
          setTemplateId(0)
        }}
        templateId={templateId}
        onOk={handleSendEmail}
      />

      <RyoushuushoModal
        visible={ryoushuushoModalVisible}
        onCancel={() => setRyoushuushoModalVisible(false)}
      />
      <SeikyuushoModal
        visible={seikyuushoModalVisible}
        onCancel={() => setSeikyuushoModalVisible(false)}
      />
      <NoushinshoModal
        visible={nouhinshoModalVisible}
        onCancel={() => setNouhinshoModalVisible(false)}
      />

      <Form
        form={form}
        name="new-order"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue,
          condition_match: initialValue == null ? 'all' : initialValue.condition_match,
          sort_order: initialValue == null ? 'ascending' : initialValue.sort_order
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
            <Card bordered={false}>
              <CopyableItem
                title="Shop Platform"
                content={initialValue.shop_platform}
                enableCopy={false}
              />
              <CopyableItem title="Platform Order Id" content={initialValue?.platform_order_id} />
              <CopyableItem title="Name" content={initialValue?.name} />
              <CopyableItem title="Customer Email" content={initialValue?.customer_email} />
              <CopyableItem title="Customer Name" content={initialValue?.customer_name} />
              <CopyableItem
                title="Shipping Name"
                content={`${initialValue?.last_name}${initialValue?.last_name ? ' ' : ''}${
                  initialValue?.first_name
                }`}
              />
              <CopyableItem
                title="Address"
                content={`${initialValue?.zip} ${initialValue?.address} ${
                  initialValue?.apartment ?? ''
                }`}
              />
              {/* <Form.Item name="zip" label="Zip">
                <Input readOnly />
              </Form.Item>
              <Form.Item name="address" label="Address">
                <Input readOnly />
              </Form.Item>
              <Form.Item name="apartment" label="Appartment">
                <Input readOnly />
              </Form.Item> */}
              <CopyableItem title="Company" content={initialValue?.company} />
              {/* <CopyableItem
                title="Tracking Company Name"
                content={initialValue?.tracking_company_name}
              /> */}
              {/* 
              <div>
                <div>Tracking Code</div>
                <div
                  style={{
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: 30
                  }}
                >
                  <div>
                    {initialValue?.tracking_url ? (
                      <a
                        href={initialValue?.tracking_url}
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: 'underline' }}
                      >
                        {initialValue?.tracking}
                        {initialValue?.tracking && <LinkOutlined />}
                      </a>
                    ) : (
                      initialValue?.tracking
                    )}
                  </div>
                </div>
              </div> */}
              <Gaps />

              <Form.Item name="transaction_status_id" label="Transaction status">
                <Select disabled={isReadOnly}>
                  {transactionStatuses?.length > 0 &&
                    transactionStatuses.map((status) => (
                      <Option key={status.id} value={status.id}>
                        {status.status}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item name="order_status_id" label="Order status">
                <Select disabled={isReadOnly}>
                  {orderStatuses?.length > 0 &&
                    orderStatuses.map((status) => (
                      <Option key={status.id} value={status.id}>
                        {status.status}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <CopyableItem title="Customer Memo" content={initialValue?.customer_memo} />
              <CopyableItem title="Operator Memo" content={initialValue?.operator_memo} />
              <Form.Item name="generated" label="Generated" valuePropName="checked">
                <Checkbox disabled>CSV Generated?</Checkbox>
              </Form.Item>

              <div>
                <div>Line Items</div>
                <Table dataSource={initialValue.line_items} columns={productCol} />
              </div>

              {initialValue?.fulfillments?.length > 0 && (
                <div>
                  <div>Fulfillments</div>
                  <Gaps />
                  {initialValue.fulfillments?.length > 0 &&
                    initialValue.fulfillments.map((item) => (
                      <div key={item.id}>
                        <Row gutter={[16, 16]}>
                          <Col>{item?.name}</Col>
                          <Col>{item?.tracking_company_name}</Col>
                          <Col>
                            {item.tracking_url ? (
                              <a
                                href={item.tracking_url}
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: 'underline' }}
                              >
                                {item?.tracking_number}
                                {item?.tracking_number && <LinkOutlined />}
                              </a>
                            ) : (
                              item?.tracking_number
                            )}
                          </Col>
                        </Row>
                        <Table dataSource={item.line_items} columns={fulfillmentCol} />
                        <Gaps />
                      </div>
                    ))}
                </div>
              )}

              <Gaps />
            </Card>
          </Col>
        </Row>

        <Gaps />
      </Form>
    </orderFormContext.Provider>
  )
}

const CopyableItem = ({ title, content, enableCopy = true }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(content)
    message.success('successfully copied to your clipboard')
  }
  return (
    <>
      <div>
        <div>{title}</div>
        <div style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', minHeight: 30 }}>
          <span>{content ?? '-'}</span>
          {enableCopy && content && (
            <Button type="link" onClick={handleCopy}>
              <CopyOutlined />
            </Button>
          )}
        </div>
      </div>
      <Gaps />
    </>
  )
}

const SendEmailModal = ({ onCancel, visible, templateId, onOk }) => {
  const { t } = useTranslation('translation')
  const { initialValue } = useContext(orderFormContext)
  const [isLoading, setIsLoading] = useState(false)
  const [form] = useForm()
  const sent = useMemo(() => {
    return initialValue.mail_delivery != null
      ? initialValue.mail_delivery[templateId.toString()]?.delivered
      : false
  }, [initialValue, templateId])

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)
    try {
      const { data: result } = await OrderService.sendPreviewEmailFromTemplate(
        initialValue.id,
        templateId,
        values.email
      )
      if (result) {
        message.success(`Preview email sent to ${values.email}`)
      }
    } catch (error) {
      message.error(`Failed to send preview email to ${values.email}`)
    }
    setIsLoading(false)
  }

  return (
    <Modal
      title="メール送信"
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      okText={t('Yes')}
      cancelText={t('No')}
    >
      <div>
        <Card title="テストメール送信" style={{ marginBottom: 16 }}>
          <Form form={form} onFinish={handleSubmit} layout="vertical">
            <Form.Item name="email" label="メールアドレス">
              <Input name="email" />
            </Form.Item>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                テストメールを送信する
              </Button>
            </div>
          </Form>
        </Card>
        <Typography.Text
          style={{
            color: sent ? 'red' : 'black'
          }}
        >
          {sent ? 'このメールは送信済みです。もう一度送信しますか？' : '送信しますか？'}
        </Typography.Text>
      </div>
    </Modal>
  )
}

const RyoushuushoModal = ({ onCancel, visible }) => {
  const [form] = Form.useForm()
  const { initialValue } = useContext(orderFormContext)
  const { t } = useTranslation('translation')

  const handleDownloadInvoices = async (values) => {
    message.loading(t('Downloading'))
    try {
      const { data: response } = await invoicePdfService.getRyoushuusho({
        ...values,
        order_id: initialValue.platform_order_id
      })
      const { data: file } = await axios.get(response.url, {
        responseType: 'blob'
      })
      saveAs(file, `領収書-${initialValue.name}.pdf`)
    } catch (e) {
      console.log(e)
      message.error(JSON.stringify(e))
    }
  }

  return (
    <Modal
      title="領収書"
      visible={visible}
      onCancel={onCancel}
      onOk={form.submit}
      okText={t('Download')}
      cancelText={t('Cancel')}
    >
      <Form
        layout="vertical"
        autoComplete="off"
        form={form}
        onFinish={(values) => {
          handleDownloadInvoices(values)
          onCancel()
        }}
        initialValues={{
          title: 'お品代として',
          name: initialValue.customer_name
        }}
      >
        <Form.Item name="title" label="Title">
          <Input />
        </Form.Item>
        <Form.Item name="name" label="Name">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const SeikyuushoModal = ({ onCancel, visible }) => {
  const [form] = Form.useForm()
  const { initialValue } = useContext(orderFormContext)
  const { t } = useTranslation('translation')

  const handleDownloadInvoices = async (values) => {
    message.loading(t('Downloading'))
    try {
      const { data: response } = await invoicePdfService.getSeikyuusho({
        ...values,
        order_id: initialValue.platform_order_id
      })
      const { data: file } = await axios.get(response.url, {
        responseType: 'blob'
      })
      saveAs(file, `請求書-${initialValue.name}.pdf`)
    } catch (e) {
      const text = await e?.message?.data?.text()
      const jsonText = JSON.parse(text)
      message.error(JSON.stringify(jsonText.messages))
    }
  }

  return (
    <Modal
      title="請求書"
      visible={visible}
      onCancel={onCancel}
      onOk={form.submit}
      okText={t('Download')}
      cancelText={t('Cancel')}
    >
      <Form
        layout="vertical"
        autoComplete="off"
        form={form}
        onFinish={(values) => {
          handleDownloadInvoices(values)
          onCancel()
        }}
        initialValues={{
          store_link: 'https://casefinite.jp/'
        }}
      >
        <Form.Item name="store_link" label="Store Link">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const NoushinshoModal = ({ onCancel, visible }) => {
  const [form] = Form.useForm()
  const { initialValue } = useContext(orderFormContext)
  const { t } = useTranslation('translation')

  const handleDownloadInvoices = async (values) => {
    message.loading(t('Downloading'))
    try {
      const { data: response } = await invoicePdfService.getNouhinsho({
        ...values,
        order_id: initialValue.platform_order_id
      })
      const { data: file } = await axios.get(response.url, {
        responseType: 'blob'
      })
      saveAs(file, `納品書-${initialValue.name}.pdf`)
    } catch (e) {
      const text = await e?.message?.data?.text()
      const jsonText = JSON.parse(text)
      message.error(JSON.stringify(jsonText.messages))
    }
  }

  return (
    <Modal
      title="納品書"
      visible={visible}
      onCancel={() => onCancel(false)}
      onOk={form.submit}
      okText={t('Download')}
      cancelText={t('Cancel')}
    >
      <Form
        layout="vertical"
        autoComplete="off"
        form={form}
        onFinish={(values) => {
          handleDownloadInvoices(values)
          onCancel()
        }}
        initialValues={{
          store_link: 'https://casefinite.jp/'
        }}
      >
        <Form.Item name="store_link" label="Store Link">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default OrderForm
