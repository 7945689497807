import axios from 'axios'

const requestHandler = (request) => {
  const secret = process.env.REACT_APP_INVOICE_PDF_SECRET

  if (secret != null && typeof secret !== 'undefined') {
    if (request.headers) {
      request.headers['Access-Secret-Token'] = secret
    }
  }

  return request
}

const successHandler = (response) => {
  return response
}

const errorHandler = (error) => {
  return Promise.reject({
    message:
      error.response?.data?.errorCode ??
      error.response?.data?.message ??
      error.response?.data?.errors ??
      error.response?.data?.code ??
      error.response ??
      'Please try again later'
  })
}

const invoicePdfClient = axios.create({
  baseURL: process.env.REACT_APP_INVOICE_PDF_BASE_URL
})
invoicePdfClient.interceptors.request.use(requestHandler)
invoicePdfClient.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
)

export default invoicePdfClient
