import React, { createContext, useState } from 'react'
import { Card, PageHeader, Space, Button, Col, Row, message, Steps, Tabs } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Gaps from '../../components/Gaps'
import FulfillmentReport from './FulfillmentReport'
import PendingFulfillmentReport from './PendingFulfillmentReport'

export const orderSelectContext = createContext()

const ReportsPage = () => {
  const [orders, setOrders] = useState([])
  const [currentStep, setCurrentStep] = useState(0)
  const [tab, setTab] = useState('1')
  const [orderIds, setOrderIds] = useState([])
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard' })
  const { t: generalT } = useTranslation('translation')
  //requried to pass message as a prop as it doesnt work in deeper component
  const msg = message
  const navigate = useNavigate()

  return (
    <orderSelectContext.Provider value={{ orderIds, setOrderIds }}>
      <PageHeader title="Reports">
        <Tabs
          defaultActiveKey="1"
          activeKey={tab}
          onChange={(key) => setTab(key)}
          type="card"
          tabBarStyle={{ marginBottom: 0 }}
        >
          {/* TODO: make the tab content into another component */}
          <Tabs.TabPane key="1" tab="配達完了">
            <Card bordered={false}>
              <FulfillmentReport />
            </Card>
          </Tabs.TabPane>
          <Tabs.TabPane key="2" tab="未配達">
            <Card bordered={false}>
              <PendingFulfillmentReport />
            </Card>
          </Tabs.TabPane>
        </Tabs>
      </PageHeader>
    </orderSelectContext.Provider>
  )
}

export default ReportsPage
