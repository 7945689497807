import React, { useEffect, useState } from 'react'
import { message, Row, Col, Space, Select, Form } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import Dragger from 'antd/lib/upload/Dragger'
import { getToken } from '../../lib/store/auth'
import OrderService from '../../network/services/order'
import { useSnapshot } from 'valtio'
import dashboardOptionsStore from '../../lib/store/dashboard_options'
import { useTranslation } from 'react-i18next'

const YamatoUploadCSV = ({ setOrders }) => {
  const [fileList, setFileList] = useState()
  const token = getToken()
  const [form] = Form.useForm()
  const idColumn = Form.useWatch('id_column', form)
  const { state } = useSnapshot(dashboardOptionsStore)
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard' })

  useEffect(() => {
    dashboardOptionsStore.state.id_column = idColumn
  }, [idColumn])

  const props = {
    // name: 'yamato_csv',
    accept: '.csv,.xls',
    fileList,
    multiple: false,
    // action: `${process.env.REACT_APP_BASE_URL}/orders/yamato-csv`,
    action: async (file) => {
      const formData = new FormData()
      formData.append('yamato_csv', file)
      formData.append('id_column', idColumn)
      if (window.confirm('Are you sure you want to upload this file?')) {
        try {
          const { data: response } = await OrderService.uploadYamatoCSV(formData)
          if (response.success) {
            message.success(`${file.name} file uploaded successfully`)
            console.log(response.data)
            setOrders([...response.data.orders])
          }
        } catch (e) {
          message.error(`${file.name} file upload failed. ${e.message}`)
        }
      }
      setFileList([])
    },
    headers: {
      contentType: 'multipart/form-data',
      Authorization: `Bearer ${token}`
    }
    // onChange(info) {
    //   console.log(info)
    //   if (info.file.status === 'done') {
    //     message.success(`${info.file.name} file uploaded successfully`)
    //     const { data: response } = info.file.response
    //     setOrders([...response.orders])
    //   } else if (info.file.status === 'error') {
    //     message.error(`${info.file.name} file upload failed.`)
    //     setFileList([])
    //   }
    // }
  }

  return (
    <>
      <Form
        form={form}
        name="download_csv"
        layout="horizontal"
        initialValues={{
          // update_to_shipped: true,
          // notify_customer: true
          id_column: 'id',
          ...state
        }}
      >
        {/* <Row>
          <Col>
            <Form.Item name="update_to_shipped" valuePropName="checked">
              <Checkbox>And update all fulfillment status to shipped</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        {updateToShipped && (
          <Row>
            <Col>
              <Form.Item name="notify_customer" valuePropName="checked">
                <Checkbox>Notify customer</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        )} */}
        <Row>
          <Col span={8}>
            <Form.Item name="id_column" label="お客様管理番号">
              <Select>
                <Select.Option value="id">Order ID</Select.Option>
                <Select.Option value="name">Order Name</Select.Option>
                <Select.Option value="platform_order_id">Platform Order ID</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Row>
        <Col>
          <Dragger {...props}>
            <Space direction="vertical" style={{ padding: 8 }}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{t('uploadTitle')}</p>
              <p className="ant-upload-hint">{t('uploadDescription')}</p>
            </Space>
          </Dragger>
        </Col>
      </Row>
    </>
  )
}

export default YamatoUploadCSV
