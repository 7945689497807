import { Button, Card, Col, Empty, Form, Input, PageHeader, Row, Select, Space, Spin } from 'antd'
import { useNavigate } from 'react-router-dom'
import { ScanOutlined } from '@ant-design/icons'
import React, { useState, useMemo, useRef, useEffect, createContext, useContext } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import Gaps from '../../components/Gaps'
import useSWR from 'swr'
import { serialize } from '../../network/request'
import OrderScanningCard from '../../components/OrderScanningCard'
import _ from 'lodash'
import FulfillmentService, { TrackingCompanies } from '../../network/services/fulfillment'
import { useTranslation } from 'react-i18next'
const shiftCharCode = (Δ) => (c) => String.fromCharCode(c.charCodeAt(0) + Δ)
const toHalfWidth = (str) => str.replace(/[！-～]/g, shiftCharCode(-0xfee0))

export const handheldScannerContext = createContext()

const Handheld = () => {
  //const [barcode, setBarcode] = useState()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const barcodeInputRef = useRef(null)
  const [submitted, setSubmitted] = useState(false)
  const sliderRef = useRef(null)
  const { t } = useTranslation('translation', { keyPrefix: 'orderScanning' })
  // const [delayDebounce, setDelayDebounce] = useState()
  const [tracking, setTracking] = useState('')
  const [trackingCompany, setTrackingCompany] = useState(TrackingCompanies[0])
  const asciiTracking = useMemo(() => (tracking ? toHalfWidth(tracking) : ''), [tracking])

  const scrollToSlider = () => {
    sliderRef.current?.scrollIntoView({ behaviour: 'smooth' })
  }

  // const handleInput = (value) => {
  //   clearTimeout(delayDebounce)
  //   const deplayDebounce = setTimeout(() => {
  //     var barcode = value?.toLowerCase() ?? ''
  //     if (barcode[0] === 'a' && barcode[barcode.length - 1] === 'a') {
  //       barcode = barcode?.slice(1, value.length - 1) ?? ''
  //     }
  //     //navigate(`/scanner/order-scanning/${barcode}`)
  //     setTracking(barcode)
  //   }, 500)
  //   setDelayDebounce(deplayDebounce)
  // }

  // useEffect(() => {
  //   const focusInput = () => barcodeInputRef?.current && barcodeInputRef?.current?.focus()

  //   focusInput()

  //   window.addEventListener('click', focusInput)

  //   return () => window.removeEventListener('click', focusInput)
  // })
  useEffect(() => {
    barcodeInputRef?.current?.focus()
  })

  const focusTrackingNumber = () => {
    setSubmitted(true)
    // barcodeInputRef?.current?.scrollIntoView({ behaviour: 'smooth' })
    barcodeInputRef?.current?.focus()
  }

  const handleSubmit = (data) => {
    if (data.barcode) {
      var barcode = data.barcode?.toLowerCase() ?? ''
      // if (
      //   // trackingCompany == 'ヤマト運輸' &&
      //   barcode[0] === 'a' &&
      //   barcode[barcode.length - 1] === 'a'
      // ) {
      barcode = barcode?.slice(1, data.barcode?.length - 1) ?? ''
      // }
      setTracking(barcode)
      setSubmitted(false)
      form.resetFields()
    }
  }

  return (
    <handheldScannerContext.Provider value={{ focusTrackingNumber, submitted }}>
      <PageHeader title=" " onBack={() => navigate('/scanner')}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Row justify="center">
            <Col span={8}>
              <div style={{ textAlign: 'center' }}>
                <ScanOutlined style={{ fontSize: 100 }} />
                <div>
                  {t('Please scan a barcode of a tracking number with your handheld scanner')}
                </div>
                <Gaps />

                <div style={{ textAlign: 'start' }}>
                  <Select
                    style={{ width: 200 }}
                    value={trackingCompany}
                    onChange={(value) => setTrackingCompany(value)}
                  >
                    {TrackingCompanies.map((item) => {
                      return (
                        <Select.Option value={item} key={item}>
                          {item}
                        </Select.Option>
                      )
                    })}
                  </Select>

                  <Gaps />

                  <Form form={form} onFinish={handleSubmit} onSubmitCapture={handleSubmit}>
                    <Form.Item name="barcode">
                      <Input
                        ref={barcodeInputRef}
                        placeholder={t('Enter tracking number')}
                        autoComplete="off"
                        style={{ opacity: 1 }}
                      />
                    </Form.Item>
                    <div style={{ textAlign: 'end' }}>
                      <Button type="primary" onClick={() => form.submit()}>
                        Submit
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
          <Gaps />
          {asciiTracking && (
            <div ref={sliderRef}>
              <OrderSlider
                tracking={asciiTracking}
                onFulfillmentSuccess={() => {
                  focusTrackingNumber()
                  setSubmitted(true)
                }}
                trackingCompany={trackingCompany}
                scrollToSlider={scrollToSlider}
              />
            </div>
          )}
        </Space>
      </PageHeader>
    </handheldScannerContext.Provider>
  )
}

const OrderSlider = ({ tracking, scrollToSlider, trackingCompany }) => {
  //check if any fulfillment matched with the tracking number
  const { submitted } = useContext(handheldScannerContext)
  const { data: response, error } = useSWR(
    FulfillmentService.byTracking([trackingCompany], tracking)
  )
  const fulfillment = _.first(response)
  const order = fulfillment?.order

  //if fulfillment exist then only get subsequent fulfillments
  const { data: nextResponse } = useSWR(
    response
      ? serialize(FulfillmentService.getAll, {
          tracking_number_gt: tracking,
          tracking_company_names: 'trackingCompany',
          limit: 10,
          sort: 'tracking_number:asc'
        })
      : null
  )

  useEffect(() => {
    if (nextResponse) scrollToSlider()
  }, [nextResponse, scrollToSlider])

  const upcomingFulfillments = FulfillmentService.toRow(nextResponse)

  if (!response) {
    return (
      <Card>
        <Spin />
      </Card>
    )
  }

  if (error || !order) {
    return (
      <Card>
        <Empty description={'No such order'} />
      </Card>
    )
  }

  return (
    <div style={{ padding: 8 }}>
      <Swiper spaceBetween={50} slidesPerView={3}>
        {!submitted && (
          <SwiperSlide key={order?.id}>
            <OrderScanningCard trackingNumber={tracking} trackingCompany={trackingCompany} />
          </SwiperSlide>
        )}
        {upcomingFulfillments.length > 0 &&
          upcomingFulfillments.map((item) => (
            <SwiperSlide key={item?.id}>
              <OrderScanningCard trackingNumber={item.tracking_number} active={false} />
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  )
}
export default Handheld
