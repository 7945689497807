import { proxy, subscribe } from 'valtio'
import _ from 'lodash'

const initialTableAttr = {
  id_column: 'id',
  exclude_sku: ''
}

const storageName = 'dashboard_options'
let storedTableAttr = { ...initialTableAttr }
if (window.localStorage) {
  let result = JSON.parse(localStorage.getItem(storageName))
  if (!_.isEmpty(result)) {
    console.log(result)
    storedTableAttr = result
  }
}

const tableAttr = proxy({
  state: storedTableAttr,
  reset() {
    tableAttr.state = { ...initialTableAttr }
    localStorage.setItem(storageName, JSON.stringify(initialTableAttr))
  }
})

subscribe(tableAttr, () => {
  console.log('state.arr has changed to', tableAttr.state)
  localStorage.setItem(storageName, JSON.stringify(tableAttr.state))
})

export default tableAttr
