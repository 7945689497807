import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, Col, Form, Input, Row, Table, message } from 'antd'

import CustomerService from '../../network/services/customer'

import Gaps from '../../components/Gaps'

const CustomerForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh,
  order,
  product,
  updateAllowed = false,
  createAllowed = false,
  readAllowed = false,
  deleteAllowed = false
}) => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [isReadOnly, setIsReadOnly] = useState(false)
  useEffect(() => {
    //check the access level
    if ((initialValue != null && !readAllowed) || (initialValue === null && !createAllowed)) {
      navigate('/customer')
    }
  })

  useEffect(() => {
    setIsReadOnly(!updateAllowed && initialValue != null)
  }, [updateAllowed, initialValue])

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    if (initialValue?.id) {
      // update event
      try {
        const { data: result } = await CustomerService.update(initialValue.id, values)
        if (result.success) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update customer error ' + error.message)
      }
    } else {
      // new customer
      try {
        const { data: result } = await CustomerService.create(values)
        if (result.success) {
          message.success('Create successful')
          navigate(`/dashboard/customer/${result.data.id}`)
        }
      } catch (error) {
        message.error('Create customer error ' + error.message)
      }
    }

    setIsLoading(false)
  }

  const orderCol = [
    {
      title: 'Order',
      dataIndex: 'order',
      key: 'order'
    },
    {
      title: 'Transaction Status',
      dataIndex: 'transaction_status',
      key: 'transaction_status'
    },
    {
      title: 'Fulfillment Status',
      dataIndex: 'fulfillment_status',
      key: 'fulfillment_status'
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at'
    }
  ]

  const productCol = [
    {
      title: 'Product Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity'
    }
  ]

  return (
    <>
      <Form
        form={form}
        name="new-customer"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue,
          condition_match: initialValue == null ? 'all' : initialValue.condition_match,
          sort_order: initialValue == null ? 'ascending' : initialValue.sort_order
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
            <Card bordered={false}>
              <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, message: 'Please insert email' }]}
              >
                <Input readOnly={isReadOnly} />
              </Form.Item>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Please insert name' }]}
              >
                <Input readOnly={isReadOnly} />
              </Form.Item>
              <Form.Item
                name="phone"
                label="Phone"
                rules={[{ required: true, message: 'Please insert phone' }]}
              >
                <Input readOnly={isReadOnly} />
              </Form.Item>
            </Card>
            {id !== 'new' && (
              <Card>
                <Table dataSource={order} columns={orderCol} />
                <Table dataSource={product} columns={productCol} />
              </Card>
            )}
          </Col>
        </Row>

        <Gaps />
      </Form>
    </>
  )
}

export default CustomerForm
