import { Button, Card, Col, Row, Space, Steps, Typography, message } from 'antd'
import Gaps from './Gaps'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { SagawaDownloadCSV, SagawaUploadCSV } from './SagawaDashboard'

const SagawaTab = () => {
  const [orders, setOrders] = useState([])
  const [currentStep, setCurrentStep] = useState(0)
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard' })
  const { t: generalT } = useTranslation('translation')
  //requried to pass message as a prop as it doesnt work in deeper component
  const msg = message
  const navigate = useNavigate()

  return (
    <Card bordered={false}>
      <Typography.Title level={4}>{generalT('Sagawa')}</Typography.Title>
      <Gaps />
      <Row>
        <Col>
          <Steps current={currentStep}>
            <Steps.Step
              style={{ cursor: 'pointer' }}
              title="Step 1"
              description={t('step1')}
              onClick={() => setCurrentStep(0)}
            />
            <Steps.Step
              style={{ cursor: 'pointer' }}
              title="Step 2"
              description={t('step2')}
              onClick={() => setCurrentStep(1)}
            />
            <Steps.Step
              style={{ cursor: 'pointer' }}
              title="Step 3"
              description={t('step3')}
              onClick={() => setCurrentStep(2)}
            />
            <Steps.Step
              style={{ cursor: 'pointer' }}
              title="Step 4"
              description={t('step4')}
              onClick={() => setCurrentStep(3)}
            />
          </Steps>
        </Col>
      </Row>
      <Gaps />
      {currentStep > 0 && (
        <Button
          onClick={() => setCurrentStep(currentStep - 1)}
          type="primary"
          style={{ marginRight: 8 }}
        >
          Back
        </Button>
      )}
      {currentStep < 3 && (
        <Button onClick={() => setCurrentStep(currentStep + 1)} type="primary">
          Next
        </Button>
      )}
      {currentStep === 3 && (
        <Button onClick={() => setCurrentStep(0)} type="primary">
          Finish
        </Button>
      )}
      <Gaps />
      {currentStep === 0 && (
        <Card>
          <SagawaDownloadCSV message={msg} />
        </Card>
      )}
      {currentStep === 1 && (
        <Space direction="vertical">
          <Button
            type="primary"
            shape="round"
            size={'large'}
            onClick={() => {
              window.open('https://www.e-service.sagawa-exp.co.jp/a/wtx/spastart.jsp')
            }}
          >
            {generalT('Sagawa')}
          </Button>
        </Space>
      )}

      {currentStep === 3 && (
        <>
          <Card>
            <SagawaUploadCSV setOrders={setOrders} />
          </Card>

          {orders?.length > 0 && (
            <Card>
              <h3>Updated tracking code for the following orders</h3>
              <Space>
                {orders.map((order, index) => (
                  <Card key={order.id ?? index}>
                    <Row>
                      <Col>{order.id}</Col>
                      <Col>
                        <Button type="primary" onClick={() => navigate(`order/${order.id}`)}>
                          View
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                ))}
              </Space>
            </Card>
          )}
        </>
      )}
    </Card>
  )
}

export default SagawaTab
