import client from '../request'

const fulfillment = (data) => {
  return client.post('/reports/fulfillment', data, {
    responseType: 'blob'
  })
}

const pendingFulfillment = (data) => {
  return client.post('/reports/pending-fulfillment', data, {
    responseType: 'blob'
  })
}

const ReportService = {
  fulfillment,
  pendingFulfillment
}
export default ReportService
